// src/components/MeetingRoom.js

import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { Box, Input, Button, VStack, HStack, Text } from '@chakra-ui/react';

// Dynamically determine the socket server URL based on the environment
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || 'http://localhost:3001';

const socket = io(SOCKET_SERVER_URL);

function MeetingRoom() {
  const { roomId } = useParams(); // Get the room ID from the URL
  const [messages, setMessages] = useState([]); // State to store chat messages
  const [newMessage, setNewMessage] = useState(''); // State to store the new message input

  useEffect(() => {
    console.log(`Connecting to room: ${roomId}`);
    // Join the room
    socket.emit('join-room', roomId, socket.id);

    // Listen for existing messages
    socket.on('load-messages', (loadedMessages) => {
      console.log('Loaded messages:', loadedMessages);
      setMessages(loadedMessages);
    });

    // Listen for incoming chat messages
    socket.on('receive-message', (newMessage) => {
      console.log(`Received message from ${newMessage.userId}: ${newMessage.message}`);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      console.log(`Disconnecting from room: ${roomId}`);
      socket.disconnect(); // Clean up the socket connection on component unmount
    };
  }, [roomId]);

  // Function to remove messages older than 12 hours
  const removeOldMessages = () => {
    const now = Date.now();
    setMessages((prevMessages) => prevMessages.filter(
      msg => now - msg.timestamp < 12 * 60 * 60 * 1000 // 12 hours in milliseconds
    ));
  };

  // Use effect to periodically remove old messages every minute
  useEffect(() => {
    const interval = setInterval(removeOldMessages, 60 * 1000); // Check every minute
    return () => clearInterval(interval);
  }, []);

  // Handle sending a new chat message
  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      console.log(`Sending message: ${newMessage}`);
      const timestamp = Date.now();
      socket.emit('chat-message', roomId, newMessage, socket.id);
      setNewMessage(''); // Clear the input field
    }
  };

  return (
    <Box p={4} maxW="500px" mx="auto">
      <VStack align="stretch" spacing={3} maxH="300px" overflowY="auto" mb={4} borderWidth="1px" borderRadius="lg" p={4}>
        {messages.map((msg, index) => (
          <HStack key={index} spacing={3} align="start">
            <Text fontWeight="bold">{msg.userId}:</Text>
            <Text>{msg.message}</Text>
          </HStack>
        ))}
      </VStack>
      <HStack>
        <Input
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <Button onClick={handleSendMessage} colorScheme="teal">
          Send
        </Button>
      </HStack>
    </Box>
  );
}

export default MeetingRoom;
