// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import MeetingRoom from './components/MeetingRoom';
import { Box, Button, ChakraProvider, Heading } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:roomId" element={<MeetingRoom />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

function Home() {
  const navigate = useNavigate();

  // Function to generate a random 3-digit room ID
  const generateRoomId = () => {
    return Math.floor(100 + Math.random() * 900).toString();
  };

  const createRoom = () => {
    const newRoomId = generateRoomId(); // Generate a random 3-digit room ID
    console.log(`Creating new room with ID: ${newRoomId}`);
    navigate(`/${newRoomId}`); // Redirect to the new room URL
  };

  return (
    <Box textAlign="center" p={4}>
      <Heading as="h1" mb={6}>WebSocket Chat Room</Heading>
      <Button onClick={createRoom} colorScheme="teal">Create Room</Button>
    </Box>
  );
}

export default App;
